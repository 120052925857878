import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4×6\\@80% 1RM`}</p>
    <p>{`Strict CTB Pullups 4×6`}</p>
    <p>{`then,`}</p>
    <p>{`500M Row`}</p>
    <p>{`21-Ring Dips`}</p>
    <p>{`21-T2B`}</p>
    <p>{`400M Row`}</p>
    <p>{`15-Ring Dips`}</p>
    <p>{`15-T2B`}</p>
    <p>{`300M Row`}</p>
    <p>{`9-Ring Dips`}</p>
    <p>{`9-T2B`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Granite Games Open starts this Wednesday, April 10th!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Sign up at: `}<a parentName="em" {...{
            "href": "https://thegranitegames.com"
          }}>{`https://thegranitegames.com`}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      